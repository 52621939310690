<template>
    <div>
        <div class="  h-[20vh] w-full flex items-center justify-center bg-zinc-800 p-5">
            <div class="right max-w-[950px] w-full flex justify-between">
                <div class="rights">
                    <h1 class="font-bold text-lg  text-white">Copyright © 2024. All rights are reserved</h1>
                </div>
                <div class="social">
                    <div class="social flex gap-6">
                        <a href="https://www.facebook.com/profile.php?id=100092328172044"><i class="fa-brands fa-facebook text-white text-3xl hover:text-[#147EFB] duration-200 ease-in-out"></i></a>
                        <a href="https://t.me/official404vpn"><i class="fa-brands fa-telegram text-white text-3xl hover:text-[#147EFB] duration-200 ease-in-out"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CopyRight'
    }
</script>

