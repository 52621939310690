<template>
    <div  class=" flex flex-col items-center">
        <div class="box mt-14 flex justify-center items-center gap-2 p-4 shadow-xl shadow-gray-200 rounded-2xl bg-white">
                <div class="image w-[50%]">
                    <img class=" rounded-xl w-full" src="../assets/ss1.jpg" alt="">
                </div>
                <div class="image w-[50%]">
                    <img class=" rounded-xl w-full" src="../assets/ss2.jpg" alt="">
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        name: 'BoxPage'
    }
</script>

